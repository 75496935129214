<!--
<script setup>
  import { ref, defineProps,defineExpose,onMounted } from 'vue'
  //import serverConfig   from '@/helpers/config';
  import axios from 'axios';
  import Swal from "sweetalert2";


  const props = defineProps([
  'projectID',
  'productID',
      'url',
      'data',
      'bucket',
      'subdir_tag',
      'type',
      "galleryItems",
  ])
  const tk = 'Bearer '+localStorage.getItem('tk')
  const axiosInterceptor = () => {
    alert('axios')
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
            Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3000,
                    })
                this.$router.push('/')           
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
    }

   let fileList = ref(0)
   const getGalleryList =  async (tagDir) => {
      axiosInterceptor()
      axios.get(props.url, 
        { 
          params: { 
            selectId: props.projectID,
            subdir_tag:tagDir,
          }, 
          headers: { 'authorization':tk}
        }
      ).then(response=>{
        fileList  = response.data.results
        console.log(fileList)
        
        //this.$emit('getParent')
      })
     
   }
   defineExpose({
     getGalleryList
   });
  onMounted(() => {
    getGalleryList()
  });

</script>
-->
<script>

import "swiper/swiper-bundle.css";
import serverConfig   from '@/helpers/config';
import axios from 'axios';
import Swal from "sweetalert2";
import { mapActions } from "vuex";


export default {
   name:'gallery',

   props: [
    'projectID',
    'productID',
    'url',
    'data',
    'bucket',
    'subdir_tag',
    'type',
  ],
  
   setup() {
      let tk = 'Bearer '+localStorage.getItem('tk')
     
      
      return {
        tk,
      };
    },
    
  data() {
    return {
     
      UrlServer: serverConfig.EP,
      datastore: this.$store.state,
      userData: this.$store.state.auth.currentUser,
      item:{},
      galleryItems: [
      ],
      fileList:false,

    };
  },
  mounted(){

   // this.getList(this.projectID)
  },
  methods:{
    ...mapActions('layout', ['changeLayoutType', ]),
    axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
            Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3000,
                    })
                this.$router.push('/')           
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
    },
    getGalleryList(value){

      this.axiosInterceptor()
      axios.get(this.url, 
        { 
          params: { 
            selectId: this.projectID,
            subdir_tag:value,
          }, 
          headers: { 'authorization':this.tk}
        }
      ).then(response=>{
        this.fileList  = response.data.results
        for (let item of this.fileList) {
          this.galleryItems.push({src: "",thumbnail:"",w: 1200,h: 900,index:item._id})
          
        } 
      })
      },
    getFilePath(filePath,index) {

      this.axiosInterceptor()
      axios.get(`${this.UrlServer}storage/f/db/to/gcloud/get-signed-url`, 
        { 
          params: { 
            filepath: filePath,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{

            
            if (response.data){
            this.fileList[index].resp = response.data
            this.galleryItems[index].src = response.data
            this.galleryItems[index].thumbnail = response.data
            this.galleryItems[index].w = 1200
            this.galleryItems[index].h = 900
          } else {
            this.fileList[index].resp = false
          }
          
        
        })
    },
    replName(val,el){
      if (val.includes(el)){
        return val.replaceAll(el,' ')
      } else {
        return val
      }
    },
    formatKb(val){
      if (val){
        return this.roundTo(val / 1024, 1)
      } else {
        return ''
      }
    },
    fileTypeCheck(file){
      if (file){
        if (file.split('/')[0] == 'image'){
          return 'image'
        } else {
          return file
        }
      
      }
    },
    roundTo(value, decimalpositions)
    {
      var i = value * Math.pow(10,decimalpositions);
      i = Math.round(i);
      return i / Math.pow(10,decimalpositions);
    },
    imgCheck(event){
      console.log(event)
    }

  },
  components: {

  },
  expose: ['getGalleryList']
};
</script>

<template>
    <div class="card pt-10">
      <h3>Galleria fotografica</h3>
        <vue-picture-swipe :items="galleryItems"></vue-picture-swipe>
      
        <div class="product-content mt-5 mr-20 mb-50 pr-20">
                    <h4 class="fs-14 mb-3">Allegati e schede tecniche :</h4>
                    <div class="row  thRow  mb-20" >
                      <div class="col-lg-2 col-md-12">
                        <strong>Thumb</strong>
                      </div>
                      <div class="col-lg-4 col-md-12">
                        <strong>Nome File</strong>
                      </div>
                      <div class="col-lg-2 col-md-12">
                        <strong>Tipo file</strong>
                      </div>
                      <div class="col-lg-2 col-md-12">
                        <strong>Dimensioni</strong>
                      </div>
                    </div>
                    <div class="row mt-50 pr-20" v-for="(item, index) of fileList" :key="index">
                      <div class="col-lg-2 col-md-12 pr-20">
                        {{ getFilePath(item.path,index) }}
                        <template v-if="fileList[index].resp">
                          <a :href="fileList[index].resp"><img :src="fileList[index].resp" alt="" class="rounded avatar-sm shadow" @load="imgCheck"  /></a>
                            <br><a :href="fileList[index].resp">Scarica</a>
                        </template>
                      </div>
                      <div class="col-lg-4 col-md-12">
                        {{ item.originalname }}
                      </div>
                      <div class="col-lg-2 col-md-12">
                        {{ item.mimetype }}
                      </div>
                      <div class="col-lg-2 col-md-12">
                        {{ formatKb(item.size) }}
                      </div>
                    </div>         
        </div>
       
    </div>
</template>
